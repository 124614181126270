import {
  createStyles,
  Container,
  Text,
  Button,
  Group,
  Stack,
} from '@mantine/core';
import notFound from '@/static/images/illustrations/undraw_page_not_found.svg';
import Link from 'next/link';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  image: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '40%',
  },

  title: {
    textAlign: 'center',
    fontWeight: 300,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 540,
    fontWeight: 300,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
  },
}));

export function NotFoundPage() {
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <Stack>
        <img className={classes.image} src={notFound} alt="not found" />
        <Text
          color="dimmed"
          size="lg"
          align="center"
          className={classes.description}
        >
          Page you are trying to open does not exist. You may have mistyped the
          address, or the page has been moved to another URL. If you think this
          is an error contact support.
        </Text>
        <Group position="center">
          <Link href="/tests" passHref={true} legacyBehavior>
            <Button component="a" color="green" variant="outline" size="md">
              Take me back to tests
            </Button>
          </Link>
        </Group>
      </Stack>
    </Container>
  );
}

export default NotFoundPage;
